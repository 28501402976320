import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router'
import { History } from 'history';

import { AppState, AppActionTypes } from 'app.types';

import pexaReducer from 'containers/pexa/redux/reducer'
import settlementInfoReducer from 'containers/calculators/settlement/redux/reducer';
import commonReducer from 'redux/common/reducer';
import firstTitleReducer from 'containers/firsttitle/redux/reducer';
import globalXReducer from 'containers/globalx/redux/reducer';
import userAuditTrailReducer from 'containers/konekta/redux/reducer';
import emailArchiverReducer from 'containers/email-archiver/redux/reducer';
import eCafReducer from 'containers/ecaf/redux/reducer';
import securexchangeReducer from '../containers/securexchange/redux/reducer';
import rapidBuildingInspectionsReducer from 'containers/rapidBuildingInspections/redux/reducer';
import specialMeterReadingInfoReducer from 'containers/calculators/specialMeterReading/redux/reducer';
import stewartTitleReducer from 'containers/stewartTitle/redux/reducer';
import legalAidReducer from 'containers/legalaid/redux/reducer';
import nzFileTransformersReducer from 'containers/nzFileTransformers/redux/reducer';



const createRootReducer = (history: History<any>): Reducer<AppState, AppActionTypes> => combineReducers(
    {
        router: connectRouter(history),
        common: commonReducer,
        pexa: pexaReducer,
        securexchange: securexchangeReducer,
        globalx: globalXReducer,
        settlementInfo: settlementInfoReducer,
        firstTitle: firstTitleReducer,
        userAuditTrail: userAuditTrailReducer,
        emailArchiver: emailArchiverReducer,
        eCaf: eCafReducer,
        RPI: rapidBuildingInspectionsReducer,
        specialMeterReadingInfo: specialMeterReadingInfoReducer,
        stewartTitle: stewartTitleReducer,
        legalAid: legalAidReducer,
        nzTransformer: nzFileTransformersReducer
    }
)

export default createRootReducer