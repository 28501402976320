
import { GenerateCSVSuccess, NZFileTransformActionType } from './actionTypes';
import * as CONSTANTS from './constants'

export interface INZFileTransformState {
    gotResponse: boolean,
    success: boolean
    requestType: string,
    generateABAResponse: boolean,
    generateCSVResponse: string

}

const initialState: INZFileTransformState = {
    gotResponse: false,
    success: false,
    requestType: '',
    generateABAResponse: false,
    generateCSVResponse: ''
}

export function nzFileTransformersReducer(state: INZFileTransformState = initialState, action: NZFileTransformActionType): INZFileTransformState {
    switch (action.type) {
        case CONSTANTS.ABA_TRANSFORM_REQUESTED:
            return {
                ...state,
                requestType: action.type,
            };
        case CONSTANTS.ABA_TRANSFORM_SUCCESS:
            return {
                ...state,
                gotResponse: true,
                success: true,
                requestType: action.type,
                generateCSVResponse: (action as GenerateCSVSuccess).data
            };
        case CONSTANTS.ABA_TRANSFORM_FAILED:
            return {
                ...state,
                gotResponse: true,
                requestType: action.type,
                generateCSVResponse: "failed"
            };

        default:
            return state;
    }
}

export default nzFileTransformersReducer;