
import { all, fork } from 'redux-saga/effects';

import commonSaga from 'redux/common/saga';
import pexaSaga from 'containers/pexa/redux/saga';
import securexchangeSaga from 'containers/securexchange/redux/saga'
import globalXSaga from 'containers/globalx/redux/saga';
import settlementInfoSaga from 'containers/calculators/settlement/redux/saga';
import firstTitleSaga from 'containers/firsttitle/redux/saga';
import userAuditTrailSaga from 'containers/konekta/redux/saga';
import emailArchiverSaga from 'containers/email-archiver/redux/saga';
import eCafSaga from 'containers/ecaf/redux/saga';
import rapidBuildingInspectionSaga from 'containers/rapidBuildingInspections/redux/saga';
import specialMeterReadingInfoSaga from 'containers/calculators/specialMeterReading/redux/saga';
import stewartTitleSaga from 'containers/stewartTitle/redux/saga'
import legalAidSaga from 'containers/legalaid/redux/saga';
import nzTransformersSaga from 'containers/nzFileTransformers/redux/saga';


export default function* rootSaga() {
    yield all([
        fork(commonSaga),
        fork(pexaSaga),
        fork(securexchangeSaga),
        fork(globalXSaga),
        fork(settlementInfoSaga),
        fork(firstTitleSaga),
        fork(userAuditTrailSaga),
        fork(emailArchiverSaga),
        fork(eCafSaga),
        fork(rapidBuildingInspectionSaga),
        fork(stewartTitleSaga),
        fork(specialMeterReadingInfoSaga),
        fork(legalAidSaga),
        fork(nzTransformersSaga)
    ])
}