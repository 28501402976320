import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Button, Grid, Paper, ThemeProvider, Typography } from "@material-ui/core";
import "./abaTransform.css";
import { ABAModel, ABATransformModel } from "utils/wcaApiTypes";
import { muiTheme } from "konekta-mui-theme";
import { AppState } from "app.types";
import { useDispatch, useSelector } from "react-redux";
import * as CONSTANTS from 'containers/nzFileTransformers/redux/constants';
import LoadingWidget from "components/common/loadingWidget";
import { abatransform } from "./redux/actions";
import Alert from "@material-ui/lab/Alert";



type CSVData = {
    // Define the structure of your CSV data here
    // For example:
    "To Account Number": string;
    Amount?: string;
    "Associated Participant"?: string;
    "Transaction Reference"?: string;
    ID?: string;
    Approvers?: string;
    "Matter Name"?: string;
    "Number"?: string;
    "Matter ID"?: string;
};

const ABATransformer: React.FC = () => {

    const [abaTransformModel, setData] = useState<ABATransformModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const nzTransformerState = useSelector((state: AppState) => state.nzTransformer);
    const matterId = useSelector((state: AppState) => state.common.actionstepContext?.matterContext!.id);
    const orgKey = useSelector((state: AppState) => state.common.actionstepContext?.orgKey);
    const dispatch = useDispatch();

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        nzTransformerState.generateCSVResponse = "";
        const csvFile = event.target.files?.[0] ?? "";
        if (!csvFile) return;
        setFileName(csvFile.name);
        let abaModel: ABATransformModel[] = [];

        Papa.parse(csvFile, {
            complete: (result: { data: CSVData[] | [] }) => {
                const csvData: CSVData[] = result.data as CSVData[];
                csvData.map((row) => {
                    abaModel.push(new ABATransformModel({
                        toAccountNumber: row["To Account Number"],
                        amount: row.Amount,
                        associatedParticipant: row["Associated Participant"],
                        transactionReference: row["Transaction Reference"],
                        id: row.ID,
                        approvers: row.Approvers,
                        matterName: row["Matter Name"],
                        number: row.Number,
                        matterID: row["Matter ID"]
                    }))
                });
            },
            header: true, // Change to true if CSV has headers
            skipEmptyLines: true,
        });
        setData(abaModel);
    };

    const handlaSave = async (isSaveToActionstep: boolean) => {
        // Dispatch the action to initiate the data fetching process
        setLoading(true);
        let abaDataToSave: ABAModel = new ABAModel();
        abaDataToSave.saveToActionstep = isSaveToActionstep;
        abaDataToSave.abaTransformModels = abaTransformModel;
        abaDataToSave.fileName = fileName;
        abaDataToSave.matterId = Number(matterId);
        abaDataToSave.orgkey = orgKey;
        await dispatch(abatransform(abaDataToSave));
    }


    useEffect(() => {
        // Mimic the logic of getDerivedStateFromProps
        if (nzTransformerState.gotResponse) {
            switch (nzTransformerState.requestType) {
                case CONSTANTS.ABA_TRANSFORM_REQUESTED:
                    setLoading(true);
                    break;
                case CONSTANTS.ABA_TRANSFORM_SUCCESS:
                    setLoading(false);
                    break;
                case CONSTANTS.ABA_TRANSFORM_FAILED:
                    setLoading(false);
                    break;
                default:
                    setLoading(false);
                    break;
            }
        }
    }, [nzTransformerState.gotResponse, nzTransformerState.requestType]);

    return (
        <ThemeProvider theme={muiTheme}>
            {loading ? <LoadingWidget message="Loading...." /> :
                <Paper elevation={1} className="aba-body" >
                    <Typography className="aba-heading" variant="h5" align="center">ABA Converter</Typography>
                    <Grid container spacing={3} justify="center" direction="row">
                        <Grid item xs={12}>
                            <div className="p-4 border rounded-lg shadow-md bg-white">
                                <input type="file" accept=".csv" onChange={handleFileUpload} className="mb-4" />
                                {nzTransformerState.generateCSVResponse == 'success' && <Alert severity="success">File converted successfully</Alert>}
                                {nzTransformerState.generateCSVResponse == 'failed' && <Alert severity="error">File convertion failed. Please check uploaded files for error.</Alert>}
                            </div>
                        </Grid>
                        <Grid container alignContent="flex-end" alignItems="flex-end" justify="center" spacing={2} xs={12}>
                            <Grid item>
                                <Button variant="contained" style={{ textTransform: "none" }} color="primary" size="small" className="aba-button" onClick={() => handlaSave(false)}>Convert & Download</Button>
                            </Grid>
                            <Grid item >
                                <Button variant="contained" style={{ textTransform: "none" }} color="primary" size="small" className="aba-button" onClick={() => handlaSave(true)}>Convert & Save to AS</Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            }
        </ThemeProvider>
    );
};

export default ABATransformer;
