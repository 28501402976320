import { call, put, takeLatest } from 'redux-saga/effects'

import * as CONSTANTS from './constants'
import * as ACTIONS from './actions'

import {
    rawPost
} from 'utils/request'


import axios from 'utils/axios'
import { GenerateCSV } from './actionTypes'

// by default, axios would call the json stringify function that would convert any date into utc date
// however, this will cause issues with the pdf generation where datetime is [x date]-00:00, which when converted into utc would be the previous day
// therefore the date transformer is needed to force preserve that locale date for correct pdf date generation
// the saga.ts file in special meter reading also copy this function
function dateTransformer(data: any): any {
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toLocaleString("en-US");
    }
    if (Array.isArray(data)) {
        return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) =>
            [key, dateTransformer(val)]))
    }
    return data
}
async function postAndSaveFile(url: string, params: any) {
    let response = await rawPost(url, params, { responseType: 'blob', transformRequest: [dateTransformer].concat(axios.defaults.transformRequest ?? []) });

    // Let the user save the file.
    saveAs(response.data, params.fileName);
}

export function* generateABACSV(action: GenerateCSV) {

    try {
        yield call(postAndSaveFile, `/api/nztransformer/abatransform`, action.data);
        yield put(ACTIONS.abatransformSuccess("success"));
    } catch (error) {
        yield put(ACTIONS.abatransformFailed(error?.response?.data));
    }
}

export default function* nzTransformersSaga() {
    yield takeLatest(CONSTANTS.ABA_TRANSFORM_REQUESTED, generateABACSV);

} 