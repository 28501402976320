import { ABAModel, ErrorViewModel } from "utils/wcaApiTypes";
import { ActionFailed, GenerateCSV, GenerateCSVSuccess } from "./actionTypes";
import * as CONSTANTS from './constants'

export function abatransform(data: ABAModel): GenerateCSV {
    return {
        type: CONSTANTS.ABA_TRANSFORM_REQUESTED,
        data
    }
}

export function abatransformSuccess(data: string): GenerateCSVSuccess {
    return {
        type: CONSTANTS.ABA_TRANSFORM_SUCCESS,
        data
    }
}


export function abatransformFailed(error: ErrorViewModel): ActionFailed {
    return {
        type: CONSTANTS.ABA_TRANSFORM_FAILED,
        error
    }
}


