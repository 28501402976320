import { createMuiTheme } from "@material-ui/core/styles";

export const muiTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none"// Prevents uppercase text
      },
      containedPrimary: {
        backgroundColor: "#004989", // Custom primary color
        color: "#fff", // Text color
        "&:hover": {
          backgroundColor: "#003979", // Darker shade on hover
        },
      }
    },
  },
});

